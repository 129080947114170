<template>
  <section class="orders">
    <div class="page-header">
      <h3 class="page-title"> Facture </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Mes achats</a></li>
          <li class="breadcrumb-item active" aria-current="page">Facture</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Facture</h4>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <b-input-group>
                      <b-form-input placeholder="Recherche" v-model="search"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <!-- <router-link to="/eCommerce/facture/idFacture">
                <button>détails facture</button>
            </router-link> -->
            <!-- <div class="row grid-margin">
              <div class="col-12">
                <div class="alert alert-warning" role="alert">
                  <strong>Heads up!</strong> This alert needs your attention, but it's not super important. </div>
              </div>
            </div> -->
            <div class="row overflow-auto">
              <div class="col-12">
                <b-table :items="items" id="table-list" responsive 
                :per-page="perPage" 
                :current-page="currentPage" :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc"
                :filter="search">
              <template #cell(action)="row">
                <button @click="detailsFacture(row)">voir</button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
const axios = require ('axios').default

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  data: function() {
    return {
      perPage: 10,
      sortBy: 'name',
        currentPage: 1,
        sortDesc: false,
        sortByFormatted: true,
        filterByFormatted: true,
        sortable: true,
        search:'',
        fields: [
          { key: 'slugname', sortable: true },
          { key: 'ulidorder', sortable: true },
          { key: 'ulid', sortable: true },
          { key: 'Lieu', sortable: true },
          { key: 'Prix', sortable: true },
          { key: 'status', sortable: true },
          { key: 'action', sortable: true }
        ],
        items: [
        ]
    };
  },
  computed: {
      rows() {
        return this.items.length
      }
    },
    methods: {
      detailsFacture(row){
        this.$router.push({
          name: "detailsFacture",
          params: {detailsFacture:row}
          })
        // console.log(row)
      }
    },
    mounted () {
      axios.get('https://sites.comolapapaya.com/bills')
      .then(res=>{
        // console.log('respfacture', res)
        this.items= res.data.result
      })
      .catch(err=> console.log(err))
    }
}
</script>